import { useContext, useEffect } from "react";
import "./App.css";
import "./assets/css/style.css";
import withClearCache from "./CacheBuster";
import Store, { Context } from "./components/context/Store";
import MainRouter from "./components/routing/routers/MainRouter";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp() {
    return (
        <Store>
            <MainRouter />
        </Store>
    );
}

export default App;
