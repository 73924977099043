const reducers = (state, action) => {
    switch (action.type) {
        case "UPDATE_LAST_WATCHED_VIDEO":
            const watched_video_slug = action.watched_video_slug;
            localStorage.setItem(
                "last_watched_video_slug",
                JSON.stringify(watched_video_slug)
            );
            return {
                ...state,
                last_watched_video_slug: watched_video_slug,
            };
        case "UPDATE_COUNTRY_DATA":
            const selected_country = {
                ...state.selected_country,
                ...action.selected_country,
            };
            return {
                ...state,
                selected_country: selected_country,
            };
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));

            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_ACTIVE_MENU":
            return {
                ...state,
                active_menu: action.active_menu,
            };
        case "UPDATE_BUISNESS_MODAL":
            return {
                ...state,
                register_buissness: !state.register_buissness,
            };
        case "TOGGLE_WEBINAR_FORM":
            return {
                ...state,
                is_webinar_form: !state.is_webinar_form,
            };
        case "TOGGLE_BUSINESS_MEET":
            return {
                ...state,
                is_business_meet: !state.is_business_meet,
            };
    }
};
export default reducers;
