import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        is_verified: false,
        country: "IN",
        user_token: {},
        name: "",
        phone: "",
    },

    selected_country: {
        country_code: "IND",
        flag: "https://ddl0zi5h2jlue.cloudfront.net/media/countries/flags/download.png",
        name: "India",
        phone_code: "+91",
        web_code: "IN",
    },

    country_details: {
        country: {
            country_code: "IND",
            flag: "https://ddl0zi5h2jlue.cloudfront.net/media/countries/flags/download.png",
            name: "India",
            phone_code: "+91",
            web_code: "IN",
        },
    },
    last_watched_video_slug: null,
    is_webinar_form: false,

    active_menu: "",
    register_buissness: false,
    is_upcomimg_business: true,
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
