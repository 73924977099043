import React, { Suspense, lazy, useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Context } from "../../context/Store";
import PageLoader from "../../general/loader/PageLoader";

const AppRouter = lazy(() => import("./AppRouter"));

function MainRoute() {
    const { dispatch } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchLastWacthedVideoSlug() {
            let promise = new Promise((resolve, reject) => {
                let last_watched_video_slug = localStorage.getItem(
                    "last_watched_video_slug"
                );
                last_watched_video_slug = JSON.parse(last_watched_video_slug);
                dispatch({
                    type: "UPDATE_LAST_WATCHED_VIDEO",
                    watched_video_slug: last_watched_video_slug,
                });
                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }
        fetchLastWacthedVideoSlug();
    }, []);
    return !isLoading ? (
        <>
            <Suspense fallback={<PageLoader />}>
                <Routes>
                    <Route path="/*" element={<AppRouter />} />
                </Routes>
            </Suspense>
        </>
    ) : (
        <PageLoader />
    );
}

export default MainRoute;
