import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loader from "../../../assets/lotties/button_loader.json";
export default function PageLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <Container>
            <Lottie options={defaultOptions} height={80} width={80} />
        </Container>
    );
}

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
`;
